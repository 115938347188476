/**
 * Based from
 * https://github.com/pbeshai/use-query-params/blob/master/packages/use-query-params-adapter-reach/src/index.ts for the
 * adapter and https://www.joshwcomeau.com/gatsby/the-worlds-sneakiest-route-change/ for the disableScrollUpdate
 */

import { globalHistory } from "@reach/router";
import { useState } from "react";

function makeAdapter() {
    const adapter = {
        replace(location) {
            // We don't want the page to scroll to the top if we stay on the same route (only query params were updated here)
            const state = location.state || {};
            state.disableScrollUpdate = true;
            globalHistory.navigate(location.search || "?", {
                replace: true,
                state: state
            });
        },
        push(location) {
            globalHistory.navigate(location.search || "?", {
                replace: false,
                state: location.state
            });
        },

        get location() {
            return globalHistory.location;
        }
    };

    return adapter;
}

/** Adapts @reach/router history to work with { replace, push } interface. */
export const ReachAdapter = ({ children }) => {
    const [adapter] = useState(makeAdapter);

    return children(adapter);
};
