exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-privacy-policy-en-js": () => import("./../../../src/templates/privacy-policy-en.js" /* webpackChunkName: "component---src-templates-privacy-policy-en-js" */),
  "component---src-templates-privacy-policy-fr-js": () => import("./../../../src/templates/privacy-policy-fr.js" /* webpackChunkName: "component---src-templates-privacy-policy-fr-js" */),
  "component---src-templates-vehicle-detail-js": () => import("./../../../src/templates/vehicle-detail.js" /* webpackChunkName: "component---src-templates-vehicle-detail-js" */),
  "component---src-templates-vehicle-listing-js": () => import("./../../../src/templates/vehicle-listing.js" /* webpackChunkName: "component---src-templates-vehicle-listing-js" */)
}

