import React from "react";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "./reachAdapter";

export const wrapPageElement = ({ element, props: { location } }) => {
    // Make sure the URLs look nicer.
    // Ref to the wrapper library https://github.com/pbeshai/use-query-params/pull/88/files
    // `encode` option in query-string https://github.com/sindresorhus/query-string#encode
    // `strict` option in query-string https://github.com/sindresorhus/query-string#strict; not sure if this does something
    const stringifyOptions = { encode: false, strict: false };
    // replaceIn: Makes it so on a query param change it doesn't trigger a history change
    const options = { updateType: "replaceIn" };
    return (
        <QueryParamProvider
            adapter={ReachAdapter}
            location={location}
            stringifyOptions={stringifyOptions}
            options={options}
        >
            {element}
        </QueryParamProvider>
    );
};
