import "./src/styles/global.css";
// Load fonts hosted locally
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";

import "@fortawesome/fontawesome-svg-core/styles.css";

export { wrapPageElement } from "./src/lib/wrapPageElement";

// shouldUpdateScroll fires on every route change, and it expects us to return a boolean:
// - true if we should scroll to top
// - false if we should maintain the current scroll position
// By default, this always returns true
// source: https://www.joshwcomeau.com/gatsby/the-worlds-sneakiest-route-change/
export const shouldUpdateScroll = ({ routerProps }) => {
    const disableScrollUpdate = routerProps.location.state?.disableScrollUpdate || false;

    return !disableScrollUpdate;
};
